import IconButton from "@mui/material/IconButton";
import Settings from "@mui/icons-material/Settings";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Tooltip from "@mui/material/Tooltip";
export default function MyIconSettings(props: {
  color?: string;
  onClick: Function;
}) {
  let { color, onClick } = props;
  return (
    <Tooltip
      title="更多操作"
      onClick={(event: any) => {
        // event.stopPropagation();
        // setAnchorEl(event.currentTarget);
        onClick(event);
      }}
    >
      <IconButton
        size="large"
        sx={{
          padding: "0 12px",
          "& svg": {
            color: color ? color : "rgba(255,255,255,0.8)",
            transition: "0.2s",
            transform: "translateX(0) rotate(0)",
          },
          "&:hover, &:focus": {
            bgcolor: "unset",
            "& svg:first-of-type": {
              transform: "translateX(-4px) rotate(-20deg)",
            },
            "& svg:last-of-type": {
              right: 0,
              color: color ? color : "",
              opacity: 1,
            },
          },
        }}
      >
        <Settings />
        <ArrowRight sx={{ position: "absolute", right: 4, opacity: 0 }} />
      </IconButton>
    </Tooltip>
  );
}
