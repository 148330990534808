import "./App.css";

import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignInSide from "pages/login/SignInSide";
import SignUp from "pages/login/SignUp";
import { SettingApiConfig } from "api";

import ShortcutStyle from "./pages/shortcutStyle";
import { useAppDispatch, useAppSelector } from "./app/hook";
import { ResponseUserInfoDto } from "api/swaggerApi";
import { loadUserInfo } from "app/userInfoSlice";

// import CssBaseline from "@mui/material/CssBaseline";
import { CommentDrawer } from "./pages/components/CommentDrawer/index";

import MessageConfig from "utils/Message";
// import { Toolbar, Typography } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import { AppBar, CssBaseline, Toolbar, Typography } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import { QueryDrawer } from "pages/components/QueryDrawer";
import { ViewDrawer } from "pages/components/ViewDrawer";
import { SearchHistoryDrawer } from "pages/components/SearchHistoryDrawer";
import { ContentHistoryDrawer } from "pages/components/ContentHistoryDrawer";
import { ConfirmProvider } from "material-ui-confirm";
function App() {
  SettingApiConfig();
  MessageConfig();

  return (
    <>
      {/* <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div">
            Planworld
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Router>
        <div className="text-xs router-view">
          <CssBaseline />
          {/*
          A <Routes> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Routes> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
          <Fragment>
            <Routes>
              {/* <Route  path="/dashboard">
            <Dashboard />
          </Route> */}
              <Route path="/login" element={<SignInSide />}></Route>
              <Route path="/sign-up" element={<SignUp />}>
                {/* <StaticHome /> */}
              </Route>
              <Route
                path="/todolist-shortcut/:id"
                element={
                  <RequireAuth>
                    <ShortcutStyle />
                  </RequireAuth>
                }
              ></Route>
              {/* <Route  path="/todolist-liststyle/*" element={<TodolistHome />}>
            
          </Route> */}
              {/* <Route path="/" element={<RouterMenu />}>
            
          </Route> */}
              {/* <Route
            path="/todolist-liststyle/*"
            element={
              <RequireAuth>
                <TodolistHome />
              </RequireAuth>
            }
          /> */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    {/* <TodolistHome /> */}
                    <ShortcutStyle />
                  </RequireAuth>
                }
              />
            </Routes>
          </Fragment>
        </div>
      </Router>
      <CommentDrawer />
      <QueryDrawer />
      <ViewDrawer />
      <SearchHistoryDrawer />
      <ContentHistoryDrawer />
    </>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  // let auth = useAuth();
  // let location = useLocation();
  const { id } = useAppSelector((state) => {
    return state.userInfo;
  });
  const dispatch = useAppDispatch();

  if (!id) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // 获取本地存储的内容
    let userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      return <Navigate to="/login" replace />;
    } else {
      let obj = JSON.parse(userInfo) as ResponseUserInfoDto;
      if (obj.access_token && obj.user) {
        dispatch(loadUserInfo(obj));
      } else {
        localStorage.clear();
        return <Navigate to="/login" replace />;
      }
      console.log("userInfo", userInfo);
    }
  }

  return children;
}

// function useAuth() {
//   return React.useContext(AuthContext);
// }
export default App;
