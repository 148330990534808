import {
  createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction
  } from "@reduxjs/toolkit";
// import { ResponseTodolistCollectionDto, TodolistCollection, TodolistCollectionService } from "api/swaggerApi";
import { ValidationErrors } from "app/commoninterface";
import { AxiosError } from "axios";
import { ResponseTodolistItemDto, TodolistItem, TodolistItemService } from "../../../api/swaggerApi";
  
  type shortcutStyleItem = {
    id:string,
    name:string,
  }
  type modalShow = {
    id?:number,
    status:boolean,
  }

// 创建一个实体适配器，可以对实体进行 CURD 操作
// https://redux-toolkit.js.org/api/createEntityAdapter
  const someEntityAdapter = createEntityAdapter<shortcutStyleItem>({
    // Assume IDs are stored in a field other than `book.id`
    // 可以手动指定实体的 id 字段
    //   selectId: (book) => book.bookId,

    // Keep the "all IDs" array sorted based on book titles
    // 设置实体的排序依据（有 id 的数据）
    //   sortComparer: (a, b) => a.title.localeCompare(b.title),
  });

  export const getData = createAsyncThunk<
  // 方法返回值
  ResponseTodolistItemDto,
  // dispatch 参数
  { id: number },
  {
    rejectValue: ValidationErrors
  }
>('shortcutStyleSlice/getData', async (params, { rejectWithValue }) => {
  try {
      let res = await  TodolistItemService.detail({ body: { collection: params.id} })
      return res
  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})

  export const shortcutStyleSlice = createSlice({
    name: "shortcutStyleSlice",
    initialState: someEntityAdapter.getInitialState({
      collectionData:<TodolistItem|undefined>undefined,
      hoverId:-1,




      collectionId:0,
      collectionCommentShow:false,
      collectionCommentAddShow:false,

      listId:0,
      listCommentShow:false,
      listCommentAddShow:false,

      itemId:0,
      itemAddShow:false,
      itemCommentShow:false,
      itemCommentAddShow:false,

      removeItemId:0,
      removeListId:0,
      removeCollectionId:0,
      itemDeleteVisible:false,
      itemDeleteNotConfirmAgain:false

    }),
    reducers: {
      setHoverId(state,action:PayloadAction<number>){
        state.hoverId = action.payload
      },
      setItemAddStatus(state, action:PayloadAction<boolean>){
        state.itemAddShow = action.payload
      },
      setCollectionData(state,action:PayloadAction<TodolistItem>){
        state.collectionData = action.payload
      },
      setItemDeleteVisible(state, action:PayloadAction<modalShow>) {
        if(action.payload.id){

          state.removeItemId = action.payload.id
        }
        state.itemDeleteVisible = action.payload.status
      },
      setItemDeleteNotConfirmAgain(state, action:PayloadAction<boolean>) {
        state.itemDeleteNotConfirmAgain = action.payload
      },
      setCollectionId(state, action:PayloadAction<number>) {
        state.collectionId = action.payload
      },
      
      
    },
    extraReducers: (builder) => {
      builder.addCase(getData.pending, (state, { payload }) => {
        // state.drawerShow = true
        // state.isLoading = true
      })
      builder.addCase(getData.fulfilled, (state, { payload }) => {
        state.collectionData = payload.result
        
      })
    }
  });
  
  export const {
    setHoverId,
    setCollectionData,
    setItemDeleteVisible,
    setItemDeleteNotConfirmAgain,
    setCollectionId,
    // setCollectionCommentShow,
    // setAddCollectionCommentShow,
    // setListCommentShow,
    // setAddListCommentShow,

    // setItemCommentShow,
    // setAddItemCommentShow
    
  } = shortcutStyleSlice.actions;
  
  export default shortcutStyleSlice.reducer;
  
  export const shortcutStyleItemSelectors = someEntityAdapter.getSelectors();
  