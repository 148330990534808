import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import {
    // TodolistCommentService,
    // TodolistCollectionCommentService,
    // TodolistService,
    // TodolistCollectionService,
    TodolistItemService
} from "../../../../api/swaggerApi/index";

import { AxiosError } from 'axios';
import { ValidationErrors } from "../../../../app/commoninterface";

interface CommentContentType {
    content: string
    createAt: string
    id: number
}
type sourceType = "item" | "list" | "collection"
//   export const showDrawer = createAsyncThunk<
//     // 方法返回值
//     { content: string, responseId: number,id:number,source:sourceType } | false,
//     // { res: TodolistItemComment, params: Partial<TodolistItem> } | null,
//     // dispatch 参数
//     { source: sourceType, id: number },
//     {
//       rejectValue: ValidationErrors
//     }
//   >('commentDrawerSlice/showDrawer', async (params, { rejectWithValue }) => {
//     try {
//       if (params.id) {
//         if (params.source == 'item') {
//           let res = await TodolistItemCommentService.getContent({ body: { id: params.id } })

//           return { content: res.content, responseId: res.id,id:params.id,source:params.source }
//         }
//         if (params.source == 'list') {
//           let res = await TodolistCommentService.getContent({ body: { id: params.id } })

//           return { content: res.content, responseId: res.id,id:params.id,source:params.source }
//         }
//         if (params.source == 'collection') {
//           let res = await TodolistCollectionCommentService.getContent({ body: { id: params.id } })

//           return { content: res.content, responseId: res.id,id:params.id,source:params.source }
//         }
//       }
//       return false

//     } catch (err: any) {
//       let error: AxiosError<ValidationErrors> = err // cast the error for access
//       if (!error.response) {
//         throw err
//       }
//       // We got validation errors, let's return those so we can reference in our component and set form errors
//       return rejectWithValue(error.response.data)
//     }
//   })
export const remove = createAsyncThunk<
    // 方法返回值
    boolean,
    // { res: TodolistItemComment, params: Partial<TodolistItem> } | null,
    // dispatch 参数
    { id: number },
    {
        rejectValue: ValidationErrors
    }
>('deleteModal/remove', async (params, { rejectWithValue }) => {
    try {
        // if (params.id) {
        // if (params.source === 'item') {

        //     let res = await TodolistItemService.delete({ body: { id: params.id} })
        //     return true
        // }
        // if (params.source === 'list') {

        //     let res = await TodolistService.delete({ body: { id: params.id } })
        //     return true
        // }
        // if (params.source === 'collection') {

        //     let res = await TodolistCollectionService.delete({ body: { id: params.id } })
        //     return true
        // }
        let res = await TodolistItemService.delete({ body: { id: params.id, } })
        // dispatch(getMenuList({id:0}))
        return true
        // }
        // return false

    } catch (err: any) {
        let error: AxiosError<ValidationErrors> = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})
export const deleteConfirmModal = createSlice({
    name: "deleteConfirmModal",
    initialState: {
        visible: false,
        isLoading: false,

        // source: <sourceType>'collection',
        id: 0




        // item: <TodolistItem | null>null,

        // commentContent: <TodolistItemComment | null>null
    },
    reducers: {
        closeConfirm(state) {
            state.visible = false
            state.id = 0
        },
        showConfirm(state, action: PayloadAction<{id: number }>) {
            state.visible = true
            state.id = action.payload.id

            // state.itemId = 0
            // state.listId = 0
            // state.collectionId = 0
        },
    },
    extraReducers: (builder) => {
        builder.addCase(remove.pending, (state, { payload }) => {

            state.isLoading = true
        })
        builder.addCase(remove.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.visible = false
            // state.isLoading = fabClasses
            // if (payload!=false) {
            //   // window.editor.render()
            //   if(payload.source === 'item'){
            //     state.itemId = payload.id
            //     state.source = 'item'
            //   }
            //   if(payload.source === 'list'){
            //     state.listId = payload.id
            //     state.source = 'list'
            //   }
            //   if(payload.source === 'collection'){
            //     state.collectionId = payload.id
            //     state.source = 'collection'
            //   }
            //   // if(payload.userData){

            //   // state.item = payload.params as TodolistItem
            //   // }
            // }
        })

    },
});

export const {
    // showDrawer,
    closeConfirm,
    showConfirm
} = deleteConfirmModal.actions;


export default deleteConfirmModal.reducer;
