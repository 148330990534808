
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
export interface ConfirmModalProps {
    visible: boolean;
    onCancel:((event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void)
    onOk: () => void;
    onOkNotAgain?: () => void;
    title: string
}

export function ConfirmModal(props: ConfirmModalProps) {

    const { title, onOk, onOkNotAgain, onCancel, visible } = props;
 


    //   const handleListItemClick = (value: string) => {
    //     onCancel(value);
    //   };

    return (
        <Dialog onClose={(event,reason) => onCancel(event,reason)} aria-labelledby="simple-dialog-title" open={visible}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <List>
                <ListItem  button onClick={() => onOk()}>
                    <ListItemAvatar>
                        <Avatar>
                            <DoneIcon />
                            
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="确定" />
                </ListItem>
                <ListItem  button onClick={() => onCancel()}>
                    <ListItemAvatar>
                        <Avatar>
                            <CloseIcon />
                            
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="取消" />
                </ListItem>
                {/* {
                    onOkNotAgain ? <ListItem  button onClick={() => onOkNotAgain()}>
                        <ListItemAvatar>
                            <Avatar>
                                <DeleteIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="确定（不再提示）" />
                    </ListItem> : ''
                } */}

            </List>
        </Dialog>
    );
}