import {
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  
  Paper,
  TextField,
} from "@mui/material";


import React, { useEffect, useState } from "react";
// import { TodolistItemCommentService, TodolistItemService, UpdateTodolistDto } from 'api/swaggerApi';
import { useParams } from "react-router-dom";
// import { TodolistService } from 'api/swaggerApi';
import { ITEM_TYPE, MODAL_TYPE, PERIOD, VISIBLE_STATUS } from "utils/idToName";
import enumToArr from "utils/enumToArr";
// import { ModalProps } from 'pages/components/ModalProps';
import { useAppDispatch, useAppSelector } from "app/hook";
import { Box, Button } from "@mui/material";
// import { setTodolistModalStatus } from './slice';
import { getData } from "pages/shortcutStyle/redux/slice";
import { closeListModal, resetForm } from "./slice";
import { TodolistItemService } from "../../../../api/swaggerApi";




import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export function ItemModal() {
  const dispatch = useAppDispatch();
  const { modalShow, form, modalTyle } = useAppSelector((state) => {
    return state.itemModalSlice;
  });
  const { menulist } = useAppSelector((state) => {
    return state.RouterMenuSlice;
  });
  // const {
  //   id
  // } = useAppSelector((state) => {
  //   return state.shortcutStyle.collectionId;
  // });
  React.useEffect(()=>{
    if(!modalShow){

      // dispatch(resetForm())
    }
      return () => {

      };
  },[modalShow])

  let { id: collectionId } = useParams();
  const handleClose = () => {
    // onClose()
    dispatch(closeListModal());
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // if (form) {

    let name = data.get("name");
    let desc = data.get("desc");
    let parent = data.get("parent");
    let id = undefined;
    if (form) {
      id = form.id;
    }

    TodolistItemService.update({
      body: {
        id: id,
        name: name as string,
        desc: desc as string,
        type: ITEM_TYPE.ITEM,
        parent: ((parent as unknown) as number) || form.parent,
      },
    }).then((r) => {
      dispatch(getData({ id: (collectionId as unknown) as number }));
      dispatch(closeListModal());
    });
    // }
  };
  function generateSubItems() {
    let arr:any =[]
    menulist.group.forEach(v=>{
      arr.push(<ListSubheader>{v.name}</ListSubheader>)
      menulist.collectionList
      .filter((item) => item.parent === v.id)
      .forEach((sub) => {
        arr.push(<MenuItem key={sub.id} value={sub.id}>
          {sub.name}
        </MenuItem>)
      });
    })
    
    return arr
  
}

  return (
    <Dialog
      open={modalShow}
      onClose={()=>{}}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {MODAL_TYPE[modalTyle]}项目
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            name="name"
            label="名称"
            type="email"
            fullWidth
            defaultValue={form?.name}
            // onChange={(e) => {
            //   // if(typeof e.target.value == 'number'){

            //   instance.setCollectionName(e.target.value)
            //   // }
            // }}
          />
          <TextField
            margin="dense"
            id="desc"
            name="desc"
            label="描述"
            type="email"
            fullWidth
            defaultValue={form?.desc}
          />
          <FormControl variant="standard" fullWidth className="mt-6">
            <InputLabel id="demo-simple-select-helper-label2">
              移动集合
            </InputLabel>

            <Select
              label="修改集合"
              labelId="demo-simple-select-helper-label2"
              id="demo-simple-select-helper3"
              name="parent"
              defaultValue={collectionId}
            >
              {generateSubItems()}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            取消
          </Button>

          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            确定
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
