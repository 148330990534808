import { useAppDispatch, useAppSelector } from "app/hook";
import { getData } from "pages/shortcutStyle/redux/slice";
import { ConfirmModal } from "../../../components/ConfirmModal";
import React from "react";
import { useParams } from "react-router-dom";
import { getMenuList } from "../../RouterMenu/RouterMenuSlice";
import { closeConfirm, remove } from "./slice";

export function DeleteModal(){
    const dispatch = useAppDispatch();

const {
    visible,
    id
  } = useAppSelector((state) => {
    return state.deleteConfirmModal;
  });
  let { id: collectionId } = useParams();
   return  <ConfirmModal
            title="确认删除？"
            visible={visible}
            // onOkNotAgain={() => {
            //     dispatch(remove({source,id:id}))
            //     dispatch(getData({ id: collectionId as unknown as number }))
            //     // dispatch(setItemDeleteVisible({status:false}));
            //     // dispatch(setItemDeleteNotConfirmAgain(true));
            //     // TodolistItemService.delete({ body:{id: removeItemId} }).then(v => {
            //     //     // getList()
            //     //     onRefresh()
            //     // })
            // }}
            onOk={() => {
                dispatch(remove({id:id})).then(()=>{

                    dispatch(getMenuList({id:0}))
                    dispatch(getData({ id: Number(collectionId) }))
                })
                // dispatch(getData({ id: collectionId as unknown as number }))
                // dispatch(setItemDeleteVisible({status:false}));
                // TodolistItemService.delete({  body:{id: removeItemId} }).then(v => {
                //     // getList()
                //     onRefresh()
                // })
            }}
            onCancel={(event,reson) => {

                // console.log('reason',reson);
                if(reson!="backdropClick"){

                    dispatch(closeConfirm());
                }
            }}
        />
}