import { configureStore } from '@reduxjs/toolkit';
import shortcutStyleSlice from '../pages/shortcutStyle/redux/slice';
import userInfoSlice from './userInfoSlice';
import commentDrawerSlice from '../pages/components/CommentDrawer/slice';
import collection from '../pages/components/Modal/CollectionModal/slice';
import  itemModalSlice from 'pages/components/Modal/ItemModal/slice';
import  subItemModalSlice from 'pages/components/Modal/SubItemModal/slice';
import deleteConfirmModal from 'pages/components/Modal/DeleteModal/slice';
import RouterMenuSlice from '../pages/components/RouterMenu/RouterMenuSlice';
import queryDrawerSlice from 'pages/components/QueryDrawer/slice';
import SearchHistoryDrawerSlice from 'pages/components/SearchHistoryDrawer/slice';
import viewDrawerSlice from 'pages/components/ViewDrawer/slice';
import contentHistoryDrawerSlice from 'pages/components/ContentHistoryDrawer/slice';
// import counterReducer from '../features/counter/counterSlice';
// import bookSlice from '../features/books/bookSlice';
// import staticSlice from '../features/StaticHome/StaticSlice';
// import classList from '../features/StaticHome/Class/ClassListSlice'
// import classItem from '../features/StaticHome/Class/ClassItemSlice'

// import componentList from '../features/StaticHome/Component/ComponentListSlice'
// import componentItem from '../features/StaticHome/Component/ComponentItemSlice'


// import TagList from '../features/StaticHome/Tags/TagListSlice'

const store = configureStore({
  reducer: {
    RouterMenuSlice:RouterMenuSlice,
    shortcutStyle:shortcutStyleSlice,
    userInfo:userInfoSlice,
    commentDrawerSlice:commentDrawerSlice,
    queryDrawerSlice:queryDrawerSlice,
    viewDrawerSlice:viewDrawerSlice,
    contentHistoryDrawerSlice:contentHistoryDrawerSlice,
    searchHistoryDrawerSlice:SearchHistoryDrawerSlice,
    collection:collection,
    itemModalSlice:itemModalSlice,
    subItemModalSlice:subItemModalSlice,
    deleteConfirmModal:deleteConfirmModal
    // shortcutStyleSlice:shortcutStyleSlice
    // todolist:todo
    // counter: counterReducer,
    // books:bookSlice,
    // static:staticSlice,
    // classList:classList,
    // classItem:classItem,
    // tagList:TagList,
    // // componentItem:componentItem,
    // componentList,
    // componentItem
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store