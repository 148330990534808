import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useAppDispatch, useAppSelector } from "app/hook";
import { hideMenu, setMenuShow } from "../RouterMenu/RouterMenuSlice";
import DiamondIcon from '@mui/icons-material/Diamond';
import SearchIcon from "@mui/icons-material/Search";

import { styled, alpha } from "@mui/material/styles";
import { InputBase, Menu, MenuItem } from "@mui/material";
import { showQuery, showQueryDrawer } from "../QueryDrawer/slice";
import { showHistoryDrawer } from "../SearchHistoryDrawer/slice";
import { showViewDrawer } from "../ViewDrawer/slice";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const MyToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    minHeight: "48px",
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const pages = ["最近搜索", "最近修改",'最近浏览'];
export default function ButtonAppBar() {
  const dispatch = useAppDispatch();
  const { menuShow } = useAppSelector((state) => {
    return state.RouterMenuSlice;
  });

  React.useEffect(() => {
    // example
    console.log("use eff");
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};
  const handleCloseNavMenu = (page:string) => {
    // setAnchorElNav(null);
    if(page==='最近搜索'){
      dispatch(showQueryDrawer())
    }
    if(page==='最近修改'){
      dispatch(showHistoryDrawer())
    }
    if(page==='最近浏览'){
      dispatch(showViewDrawer())
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: "#051e34" }}>
        <MyToolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={(e) => {
              // alert(123)
              // setDad(e.target.value);
              if (menuShow) {
                dispatch(hideMenu());
              } else {
                dispatch(setMenuShow());
              }
            }}
          >
            {/* <ExpandCircleDownIcon /> */}
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* 主页 */}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onKeyDown={(e)=>{
                  console.log('e',e);
                  if(e.key==='Enter'){
                    let target = e.target as HTMLInputElement
                    dispatch(showQuery({query:target.value}))
                  }
                }}
              />
            </Search>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={(e) => {
                    // set(e.target.value);
                    handleCloseNavMenu(page)
                  }}
                  sx={{ color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 2, display: { xs: "inherit", md: "none" } }}
            onClick={(e) => {
              handleClick(e)
              // alert(123)
              // setDad(e.target.value);
              // if (menuShow) {
              //   dispatch(hideMenu());
              // } else {
              //   dispatch(setMenuShow());
              // }
            }}
          >
            {/* <ExpandCircleDownIcon /> */}
            {/* <MenuIcon /> */}
            <DiamondIcon />
          </IconButton>
          {/* <Button color="inherit">Login</Button> */}
        </MyToolbar>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {pages.map((page) => {
              return <MenuItem onClick={()=>{
                handleCloseNavMenu(page)
                setAnchorEl(null);
              }}>{page}</MenuItem>
            })
          }
          
        </Menu>
      </AppBar>
    </Box>
  );
}
