export  enum MODAL_TYPE {
  '新增',
  '修改'
}
export enum ITEM_TYPE {
  "GROUP",
  "COLLECTION",
  "ITEM",
  "SUBITEM",
}
export enum ITEM_TYPE_TEXT {
  "分组",
  "集合",
  "项目",
  "子项",
}
export  enum BOOLEAN_STATUS {
  '否',
  '是',
}
  export  enum ITEM_STATUS {
    '待处理',//待处理
    // '进行中',//进行中
    // '已暂停',//已暂停
    '已解决',//已解决
  }
export enum PERIOD {
    // 每天
    "每天",
    // 每周
    "每周",
    // 每月
    "每月",
    // 每年
    "每年",
  }

  export enum VISIBLE_STATUS {
    // 每天
    'HIDDEN',
    // 每周
    'VISIBLE',
  }