
// import { Checkbox } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';

import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

import classnames from 'classnames'
import { useEffect, useState } from "react";



    // Todolist, TodolistCollection, TodolistCollectionService, 
    // , TodolistService
    import { TodolistItem, TodolistItemService } from "../../api/swaggerApi";
// import { Button, Tooltip } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useAppDispatch, useAppSelector } from "../../app/hook";
import {  setCollectionId,  setHoverId,  setItemDeleteVisible } from "./redux/slice";
import FlagIcon from '@mui/icons-material/Flag';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import Badge from '@mui/material/Badge';

import { ITEM_STATUS, PERIOD } from "../../utils/idToName";
import moment from "moment";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, ListItemButton, Popover, Typography } from '@mui/material';
import React from 'react';
import { showDrawer } from '../components/CommentDrawer/slice';
import { addSubItemModal, alterSubItemModal, resetForm } from 'pages/components/Modal/SubItemModal/slice';
import { showConfirm } from 'pages/components/Modal/DeleteModal/slice';
import MyIconSettings from '../../components/MyIconSettings';
import { setForm } from 'pages/components/Modal/ItemModal/slice';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export function ItemTemplate(props: { item: TodolistItem, todolistId: number, index: number,disableBtn:Boolean }) {

    let { item, index, todolistId } = props
    let [itemCheck, setItemCheck] = useState(item.status == ITEM_STATUS.已解决 ? true : false)
    let [periodName, setPeriodName] = useState('')
    let [colorName, setColorName] = useState('text-gray-400')
    const dispatch = useAppDispatch();
    useEffect(() => {
        setPeriodName(moment(item.startAt).calendar())
        let now = moment().format('YYYY-MM-DD')
        let isBefore = moment(item.startAt).isBefore(now)
        let isSame = moment(item.startAt).isSame(now)
        let isAfter = moment(item.startAt).isAfter(now);
        // console.log(isBefore,isSame,isAfter)
        if (isBefore) setColorName('text-red-400')
        if (isSame) setColorName('text-blue-400')
        if (isAfter) setColorName('text-gray-400')

        console.log(isBefore, isSame, isAfter)
    }, [])

    const {
        itemDeleteNotConfirmAgain,
        hoverId,
        
    } = useAppSelector((state) => {
        return state.shortcutStyle;
    });
    const { isMobile } = useAppSelector((state) => {
        return state.RouterMenuSlice;
      });
    const { form } = useAppSelector((state) => {
        return state.itemModalSlice;
      });
    


    function setItemStatus() {
        // setItemCheck(true)

        TodolistItemService.setStatus({
            body: {
                id: item.id, status: true ? ITEM_STATUS.已解决 : ITEM_STATUS.待处理,
                todolistId: todolistId
            }
        })
    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const [checked, setChecked] = React.useState(['wifi']);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return <> 
    {/* {item.queryRemark?<Box className=" text-gray-400 border-dashed pt-2 border-t-2 mt-2">{item.queryRemark}</Box>:null} */}
    <Box 
    onMouseEnter={() => {
        dispatch(setHoverId(item.id))
    }} className={classnames(`flex item-wrap item-width`,
        {
            "bg-gray-200": index % 2 != 0,
            "line-through": itemCheck
        })}
        sx={{ 
            // maxWidth: 350, minWidth: 350 ,
            minHeight: 40,padding:"5px 0",paddingLeft:1}}

        onClick={(event) => {
            console.log('event', event)
            event.stopPropagation()
            // if (event.target == event.currentTarget) {

            setItemStatus()
            // }
            dispatch(showDrawer({ id: item.id ,name:item.name}));

        }}
    >
        
        <Box className="flex justify-between flex-grow cursor-pointer" >
            
            {item.isComment?<FlagIcon sx={{fontSize:"12px"}}/>:null}
            {/* </Box> */}
            <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow:"1"
            }} variant="caption" component="p"
                className={colorName}

            >{item.name}
            {item.viewCount>0?<span className="viewCount">（{item.viewCount}）</span>:''}
            </Typography>
            


            
        </Box>
        <Box className="flex flex-col justify-center" sx={{minWidth:"50px"}}>
                {((isMobile || hoverId == item.id) && !props.disableBtn)?
                
                <MyIconSettings onClick={(event: any) => {
                    console.log('tooltip');
                    
                        event.stopPropagation()
                        setAnchorEl(event.currentTarget);
                    }} color="black"/>:null}

            </Box>
    </Box>

        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={(event) => {

                setAnchorEl(null);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                subheader={<ListSubheader>{periodName}</ListSubheader>}
            >
                <ListItemButton
                    onClick={(event) => {
                        dispatch(showDrawer({ id: item.id,name:item.name }));
                        setAnchorEl(null);
                    }}
                >
                    <ListItemText primary="查看备注" />
                </ListItemButton>
                <ListItemButton
                    onClick={(event) => {
                        dispatch(resetForm())
                        // form.id = -
                        dispatch(setForm({id:-1}))
                        dispatch(alterSubItemModal({parent:item.parent, id:item.id,todolistId:todolistId,name:item.name,desc:item.desc,period:item.period}));
                        setAnchorEl(null);
                    }}
                >
                    <ListItemText primary="修改项目" />
                </ListItemButton>
                <ListItemButton
                    onClick={(event) => {
                        
                        dispatch(showConfirm({id:item.id }))
                        setAnchorEl(null);
                    }}
                >
                    <ListItemText primary="删除" />
                </ListItemButton>

            </List>
        </Popover>
        </>
}