import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  TodolistItem, UpdateTodolistItemDto, TodolistItemService
} from "../../../../api/swaggerApi/index";
  
  import { AxiosError } from 'axios';
import { ValidationErrors } from "../../../../app/commoninterface";
import { MODAL_TYPE } from "../../../../utils/idToName";


export const getItemDetail = createAsyncThunk<
    // 返回值
    TodolistItem[],
    // actionPayload
    { id: number },
    {
        rejectValue: ValidationErrors
    }
>('CollectionModal/getItemDetail', async (actionPayload, { rejectWithValue }) => {
    try {
        // 接口实现
        let res = await TodolistItemService.getCollectionItems({ id: actionPayload.id})
        return res
    } catch (err: any) {
        let error: AxiosError<ValidationErrors> = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

  interface CommentContentType {
    content: string
    createAt: string
    id: number
  }

  let initForm:UpdateTodolistItemDto = {}
  
  // let initMenuList:MenuListResponseDto = {group:[],collectionList:[]}
  export const collection = createSlice({
    name: "collection",
    initialState: {
      modalShow:false,
      form:initForm,
      modalType:MODAL_TYPE.新增,
      collectionItems:[] as TodolistItem[]
      // menulist:initMenuList
  
  
      // item: <TodolistItem | null>null,
  
      // commentContent: <TodolistItemComment | null>null
    },
    reducers: {
      resetForm(state){
        state.form = initForm
      },
      setCollectionData(state,action:PayloadAction<UpdateTodolistItemDto>){
        state.form = action.payload
      },
      closeModal(state){
        state.modalShow = false
      },
      showModal(state,action:PayloadAction<UpdateTodolistItemDto>){
        state.modalType = MODAL_TYPE.新增
        state.modalShow = true
        state.form = action.payload
      },
      alterModal(state,action:PayloadAction<UpdateTodolistItemDto>){
        state.modalShow = true
        state.modalType = MODAL_TYPE.修改
        state.form.type = action.payload.type
        // state.form = action.payload
      },
      
    },
    extraReducers: (builder) => {
    builder.addCase(getItemDetail.rejected, (state, action) => {
    })
    builder.addCase(getItemDetail.pending, (state, { payload }) => {
      // state.isSaving = false
    })
    builder.addCase(getItemDetail.fulfilled, (state, { payload }) => {
      // state.isSaving = true
      state.collectionItems = payload
    })
    },
  });
  
  export const {
    resetForm,
    closeModal,
    showModal,
    alterModal,
    setCollectionData,
    // setMenulist,
    // setMenuExpend
    // showDrawer,
    // hideDrawer
  } = collection.actions;
  
  
  export default collection.reducer;
  