import { Breadcrumbs, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle, Grid, ListItem, ListItemIcon,
   ListItemText, ListSubheader, MenuItem, Paper, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useRef, useState } from 'react';

// import { TodolistCollection, TodolistCollectionService, UpdateTodolistCollectionDto } from 'api/swaggerApi';
import { useEffect } from 'react';
// import { ModalProps } from 'pages/components/ModalProps';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { closeModal, resetForm } from 'pages/components/Modal/CollectionModal/slice';
import { Box } from '@mui/material';
import { getData, setCollectionData } from 'pages/shortcutStyle/redux/slice';
import { TodolistItem, TodolistItemService } from '../../../../api/swaggerApi';
import { ITEM_TYPE, ITEM_TYPE_TEXT, MODAL_TYPE } from '../../../../utils/idToName';
import { getMenuList } from '../../RouterMenu/RouterMenuSlice';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


export function UpdateTodolistCollectionModal() {
  
  const {
    modalShow,
    form,
    modalType,
  } = useAppSelector((state) => {
    return state.collection;
  });
  const dispatch = useAppDispatch();
  

  const {
    collectionData
  } = useAppSelector((state) => {
    return state.shortcutStyle;
  });
  const { menulist } = useAppSelector((state) => {
    return state.RouterMenuSlice;
  });

  React.useEffect(()=>{
    if(!modalShow){

      // dispatch(resetForm())
    }
      return () => {

      };
  },[modalShow])
  
  

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (form) {

      let name = data.get('name')
      let desc = data.get('desc')
      let parent = data.get('parent')

      
      // TodolistItemService.update
      TodolistItemService.update({
        body: {
          id: form.id,
          name:  name as string,
          desc: desc  as string,
          type:form.type,
          parent:parent as unknown as number || form.parent
        }
      }).then(r => {
        // onRefresh()
        let c = null
        if(collectionData){
          c = JSON.parse(JSON.stringify(collectionData)) as TodolistItem
          c.name = name as string
          c.desc = desc as string
          
          // dispatch(setCollectionData(c))
        }
        dispatch(getMenuList({id:0}))
        if(form.type == ITEM_TYPE.COLLECTION && modalType == MODAL_TYPE.修改){
          dispatch(getData({id:form.id || 0}))
        }
        dispatch(closeModal())
      })
    }
  };
  

  return <Dialog open={modalShow} onClose={(event,reson) => {
    
    // dispatch(closeModal())
  }} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{MODAL_TYPE[modalType]}{ITEM_TYPE_TEXT[form.type||0]}</DialogTitle>
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <DialogContent>
      <FormControl variant="standard" fullWidth>
        <TextField

          margin="dense"
          id="name"
          name="name"
          label="名称"
          type="email"
          fullWidth
          defaultValue={form?.name}
          
        />
        </FormControl>
        <FormControl variant="standard" fullWidth>
        <TextField

          margin="dense"
          id="desc"
          name="desc"
          label="描述"
          type="email"
          fullWidth
          defaultValue={form?.desc}
          
        />
        </FormControl>
        {form.type == ITEM_TYPE.COLLECTION?<FormControl variant="standard" fullWidth className='mt-6'>
        <InputLabel id="demo-simple-select-helper-label">移动分组</InputLabel>
        
        <Select
            
            label="所在分组"
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            name="parent"
            defaultValue={form?.parent}
          >
            {menulist.group.map((v) => {
              return <MenuItem value={v.id}>{v.name}</MenuItem>
            })}

        </Select>
        </FormControl>:null}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          dispatch(closeModal())
        }} color="primary">
          取消
      </Button>
        {/* <Button
        onClick={() => {
          console.log('确定')
          if (form) {

            TodolistCollectionService.update({
              body: {
                id: form.id,
                name: form.name,
                desc: form.desc
              }
            }).then(r => {
              // onRefresh()

            })
          }

        }} color="primary">
        确定
      </Button> */}
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >确定</Button>

      </DialogActions>
    </Box>
  </Dialog>
}