import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
// import makeStyles from '@mui/styles/makeStyles';
// import {
//   LoginDto,
//   AuthService,
//   serviceOptions,
//   TodolistCollectionService,
// } from "../../api/swaggerApi/index";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hook";
// import { login } from "../../app/userInfoSlice";
import { useEffect } from "react";
import { loginPwd } from "../../app/userInfoSlice";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: "100vh",
//   },
//   image: {
//     backgroundImage: "url(/loginBg.jpg)",
//     backgroundRepeat: "no-repeat",
//     backgroundColor:
//       theme.palette.mode === "light"
//         ? theme.palette.grey[50]
//         : theme.palette.grey[900],
//     backgroundSize: "cover",
//     backgroundPosition: "left",
//   },
//   paper: {
//     margin: theme.spacing(8, 4),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

export default function SignInSide() {
  // const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    beforePath
  } = useAppSelector((state) => {
    return state.userInfo;
  });

//   useEffect(()=>{
//     if(id){
// let beforePath = localStorage.getItem("beforePath");
//                       if (beforePath) {
//                         navigate(beforePath)
//                       }
//     }
//   },[id])
  let [userName,setUserName] = useState('')
  let [password,setPassword] = useState('')
  let [remeberMe,setRemeberMe] = useState(false)

  async function onSubmit() {
      const resultAction = await dispatch(loginPwd({ username: userName, password: password }))
      console.log('resultAction',resultAction)
      if (loginPwd.fulfilled.match(resultAction)) {

        if(remeberMe){
          localStorage.setItem('userInfo',JSON.stringify(resultAction.payload))
        }else{
          localStorage.clear()
        }

        navigate(beforePath)
        // alert('登录成功')
        // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
        // const user = resultAction.payload
        // showToast('success', `Updated ${user.first_name} ${user.last_name}`)
        
      } else {
        if (resultAction.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
          // formikHelpers.setErrors(resultAction.payload.field_errors)
        } else {
          // showToast('error', `Update failed: ${resultAction.error}`)
        }
      }
    }
  return (
    <Grid container component="main" >
      {/* className={classes.root} */}
      <CssBaseline />
      {/* className={classes.image} */}
      <Grid item xs={false} sm={4} md={7}  />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {/* className={classes.paper} */}
        <div >
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            登录
          </Typography>
          <form
            id="form"
            // className={classes.form}
            noValidate
            
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="用户名"
              name="username"
              autoFocus

              value={userName}
              onChange={(event)=>{
                setUserName(event.target.value);
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="密码"
              type="password"
              id="password"
              value={password}
              onChange={(event)=>{
                setPassword(event.target.value);
              }}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value={remeberMe}
              onChange={(event)=>{
                setRemeberMe(event.target.checked);
              }} color="primary" />}
              label="记住我"
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
              onClick={()=>{
                onSubmit()
              }}
            >
              登录
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  忘记密码?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  {"注册新账户"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
