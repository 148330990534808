import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
// import { ResponseTodolistCollectionDto, TodolistCollection, TodolistCollectionService } from "api/swaggerApi";
import { ValidationErrors } from "app/commoninterface";
import { AxiosError } from "axios";
import { MenuListResponseDto, ResponseTodolistItemDto, setMenuExpandDto, TodolistItem, TodolistItemService } from "../../../api/swaggerApi";

export const getMenuList = createAsyncThunk<
// 方法返回值
MenuListResponseDto,
// dispatch 参数
{ id: number },
// 错误返回值
{
  rejectValue: ValidationErrors
}>
('RouterMenuSlice/getMenuList', async (params, { rejectWithValue }) => {
try {
    let res = await TodolistItemService.menulist({ body: { id: params.id} })
    
    return res
} catch (err: any) {
  let error: AxiosError<ValidationErrors> = err // cast the error for access
  if (!error.response) {
    throw err
  }
  // We got validation errors, let's return those so we can reference in our component and set form errors
  return rejectWithValue(error.response.data)
}
})

export const setMenuExpand = createAsyncThunk<
// 正常操作返回值
setMenuExpandDto,
// dispatch 参数
{ id: number,status:boolean },
// 错误返回值
{
  rejectValue: ValidationErrors
}>
('RouterMenuSlice/setMenuExpand', async (params, { rejectWithValue }) => {
try {
    let res = await TodolistItemService.setMenuExpand({ body: { id: params.id,status:params.status} })
    
    return res
} catch (err: any) {
  let error: AxiosError<ValidationErrors> = err // cast the error for access
  if (!error.response) {
    throw err
  }
  return rejectWithValue(error.response.data)
}
})

let initMenuList:MenuListResponseDto = {group:[],collectionList:[]}
export const RouterMenuSlice = createSlice({
  name: "RouterMenuSlice",
  initialState:{
    
    isMobile:false,
      menuShow:window.innerWidth>1024?true:false,
      MenuMoreShow:false,
      menulist:initMenuList
    },
  reducers: {
    // setMenuExpend(state,action:PayloadAction<number>){
    //   state.menulist.group = state.menulist.group.map(v=>{
    //     if(v.id == action.payload){
    //       v.expend = !v.expend
    //     }
    //     return v
    //   }) 
    // },
    setIsMobile(state,action:PayloadAction<boolean>){
      state.isMobile = action.payload
    },
    setMenuShow(state){
      state.menuShow = true
    },
    hideMenu(state){

      state.menuShow = false
    },
    setMenuMoreShow(state, action:PayloadAction<boolean>){
      state.MenuMoreShow = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuList.pending, (state, { payload }) => {
    })
    builder.addCase(getMenuList.fulfilled, (state, { payload }) => {
      state.menulist = payload
      state.menulist.group = payload.group.map(v=>{
        v.secondaryText = state.menulist.collectionList.filter(item=>item.parent==v.id).map(h=>h.name).join(",")
        return v
      })
    })
    builder.addCase(setMenuExpand.pending, (state, { payload }) => {
    })
    builder.addCase(setMenuExpand.fulfilled, (state, { payload }) => {
      // state.menulist = payload
      state.menulist.group = state.menulist.group.map(v=>{
        if(v.id == payload.id){
          v.expend = payload.status
        }
        return v
      }) 
      
      // state.menulist.group = payload.group.map(v=>{
      //   v.secondaryText = state.menulist.collectionList.filter(item=>item.parent==v.id).map(h=>h.name).join(",")
      //   return v
      // })
    })
  }
});

export const {
  
  setIsMobile,
  setMenuShow,
  hideMenu,
  setMenuMoreShow,
  // setMenuExpend
} = RouterMenuSlice.actions;

export default RouterMenuSlice.reducer;

// export const shortcutStyleItemSelectors = someEntityAdapter.getSelectors();
