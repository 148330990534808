import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  TodolistItem, UpdateTodolistItemDto,
  TodolistItemService
} from "../../../../api/swaggerApi/index";
  
  import { AxiosError } from 'axios';
import { ValidationErrors } from "../../../../app/commoninterface";
import { MODAL_TYPE } from "utils/idToName";
  interface CommentContentType {
    content: string
    createAt: string
    id: number
  }
  export const addTodolist = createAsyncThunk<
    // 方法返回值
    // { content: string, responseId: number,id:number } | false,
    TodolistItem,
    // { res: TodolistItemComment, params: Partial<TodolistItem> } | null,
    // dispatch 参数
    { form:UpdateTodolistItemDto,collectionId:number },
    {
      rejectValue: ValidationErrors
    }
  >('itemModalSlice/showDrawer', async (params, { rejectWithValue }) => {
    try {
      let res = await TodolistItemService.update({
            body: {
              id:params.form.id,
              name: params.form.name,
              desc: params.form.desc,
              parent:params.collectionId
              // collectionId: params.collectionId,
              // startAt: start,
              // endAt: end
            }
          })
          return res
          
  
    } catch (err: any) {
      let error: AxiosError<ValidationErrors> = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data)
    }
  })
  let initForm:UpdateTodolistItemDto = {}
  export const itemModalSlice = createSlice({
    name: "itemModalSlice",
    initialState: {
      modalShow:false,
      modalTyle:MODAL_TYPE.新增,
      form:initForm
      
  
  
      // item: <TodolistItem | null>null,
  
      // commentContent: <TodolistItemComment | null>null
    },
    reducers: {
      // setTodolistModalStatus(state, action:PayloadAction<{status:boolean,form:UpdateTodolistDto}>){
      //   state.modalShow = action.payload
      // },
      resetForm(state){
        state.form = initForm
      },
      closeListModal(state){
        state.modalShow = false
      },
      setForm(state,action:PayloadAction<UpdateTodolistItemDto>){
        state.form = action.payload
      },
      addItemModal(state){
        state.modalShow = true
        state.modalTyle = MODAL_TYPE.新增
        // state.form = action.payload
      },
      alterItemModal(state,action:PayloadAction<UpdateTodolistItemDto>){
        state.modalShow = true
        state.modalTyle = MODAL_TYPE.修改
        state.form = action.payload
      }
    //   hideDrawer(state) {
    //     state.drawerShow = false
    //     state.content = ''
    //     state.itemId = 0
    //     state.listId = 0
    //     state.collectionId = 0
    //     state.responseId = undefined
    //     state.isLoading = false
    //   },
    },
    extraReducers: (builder) => {
    //   builder.addCase(showDrawer.pending, (state, { payload }) => {
    //     state.drawerShow = true
    //     state.isLoading = true
    //   })
    //   builder.addCase(showDrawer.fulfilled, (state, { payload }) => {
    //     state.isLoading = false
    //     console.log('showDrawer',payload)
    //     if (payload!=false) {
    //       state.content = payload.content
    //       // window.editor.render()
    //       state.responseId = payload.responseId
    //       if(payload.source === 'item'){
    //         state.itemId = payload.id
    //         state.source = 'item'
    //       }
    //       if(payload.source === 'list'){
    //         state.listId = payload.id
    //         state.source = 'list'
    //       }
    //       if(payload.source === 'collection'){
    //         state.collectionId = payload.id
    //         state.source = 'collection'
    //       }
    //       // if(payload.userData){
  
    //       // state.item = payload.params as TodolistItem
    //       // }
    //     }
    //   })
    //   builder.addCase(showDrawer.rejected, (state, action) => {
    //   })
    //   builder.addCase(saveComment.pending, (state, { payload }) => {
    //     state.isSaving = false
    //   })
    //   builder.addCase(saveComment.fulfilled, (state, { payload }) => {
    //     state.isSaving = true
    //   })
    },
  });
  
  export const {
    resetForm,
    setForm,
    closeListModal,
    addItemModal,
    alterItemModal
    // showDrawer,
    // hideDrawer
  } = itemModalSlice.actions;
  
  
  export default itemModalSlice.reducer;
  