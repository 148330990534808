import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import {
  CommonService,
  QueryResponseDto,
  // TodolistItem,
  TodolistItemCommentService
} from "../../../api/swaggerApi/index";

import { AxiosError } from 'axios';
import { ValidationErrors } from "../../../app/commoninterface";

export const showQuery = createAsyncThunk<
  // 方法返回值
  QueryResponseDto | false,
  // { res: TodolistItemComment, params: Partial<TodolistItem> } | null,
  // dispatch 参数
  { query: string },
  {
    rejectValue: ValidationErrors
  }
>('queryDrawerSlice/showQuery', async (params, { rejectWithValue }) => {
  try {
    if (params.query) {
        let res = await CommonService.query({ body: { query: params.query } })

        return res
      
    }
    return false

  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})
interface initType {
  drawerShow: boolean
  queryRes: QueryResponseDto|null
}
let initialState:initType = {
  drawerShow:false,
  queryRes:null
}
export const queryDrawerSlice = createSlice({
  name: "queryDrawerSlice",
  initialState,
  reducers: {
    
    setIsSavingFalse(state){
      // state.isSaving = false

    },
    showQueryDrawer(state) {
      state.drawerShow = true
    },
    hideDrawer(state) {
      state.drawerShow = false
      // state.isSaving = false
      // state.drawerShow = false
      // state.content = ''
      // state.id = 0
      // state.responseId = undefined
      // state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showQuery.pending, (state, { payload }) => {
      state.drawerShow = true
      
      // state.isLoading = true
    })
    builder.addCase(showQuery.fulfilled, (state, { payload }) => {
      
      // state.isLoading = false
      // console.log('showQuery',payload)
      if (payload!=false) {
        state.queryRes = payload
      }
    })
    builder.addCase(showQuery.rejected, (state, action) => {
      // state.saveErr = true
    })
    // builder.addCase(saveComment.pending, (state, { payload }) => {
    //   state.isSaving = false
    //   state.saveErr = false
    // })
    // builder.addCase(saveComment.fulfilled, (state, { payload }) => {
    //   state.isSaving = true
    // })
  },
});

export const {
  setIsSavingFalse,
  showQueryDrawer,
  hideDrawer
} = queryDrawerSlice.actions;


export default queryDrawerSlice.reducer;
