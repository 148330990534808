import {
    createTheme,
    ListItemText,
    ListSubheader,
    Popover,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
} from "@mui/material";
import List from '@mui/material/List';
import { ListItemButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { ITEM_TYPE } from "../../../utils/idToName";
import { alterModal, resetForm, showModal } from "../Modal/CollectionModal/slice";
import { showConfirm } from "../Modal/DeleteModal/slice";


// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }


export function GrouopMorePopover(props:{
    anchorEl:HTMLButtonElement | null,
    parentId:number,
    onClose:Function
}) {
    let dispatch = useAppDispatch()
    const {
        form
      } = useAppSelector((state) => {
        return state.collection;
      });
    const {onClose,anchorEl,parentId} = props
    const show = Boolean(anchorEl);
    return <Popover
        open={show}
        anchorEl={anchorEl}
        onClose={(event) => {
            onClose();
        }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >

            <ListItemButton
                onClick={(event) => {
                    dispatch(resetForm())
                    dispatch(showModal({ name: "", desc: "", type: ITEM_TYPE.COLLECTION,parent:parentId }))
                    // dispatch(showModal({ name: "", desc: "", type: ITEM_TYPE.GROUP }))
                    onClose();
                }}
            >
                <ListItemText primary="添加集合" />
            </ListItemButton>
            <ListItemButton
                onClick={(event) => {
                    dispatch(alterModal({type: ITEM_TYPE.GROUP }))

                    // dispatch(showModal({ name: "", desc: "", type: ITEM_TYPE.GROUP }))
                    onClose();
                }}
            >
                <ListItemText primary="修改分组" />
            </ListItemButton>
            <ListItemButton
                onClick={(event) => {
                    if(form.id){

                        dispatch(showConfirm({id:form.id}))
                    }
                    onClose(null);
                }}
            >
                <ListItemText primary="删除分组" />
            </ListItemButton>
        </List>
    </Popover>
}