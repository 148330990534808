import { EnumType } from "typescript";



export default function enumToArr(e: any) {
    let arr = []
    for (const value in e) {

        // log(value);
        if (isNaN(Number(value))) {
        }else{
            
            arr.push(+value)
        }
    } 
    // console.log('arr',arr)
    return arr;
}