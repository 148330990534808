import {
  createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction
  } from "@reduxjs/toolkit";
  import {
    LoginDto,
    AuthService,
    serviceOptions,
    ResponseUserInfoDto,
  } from "../api/swaggerApi/index";
  
import { AxiosError } from 'axios'
import { ValidationErrors } from "./commoninterface";

export interface User {
  id: string
  first_name: string
  last_name: string
  email: string
}
  type userInfoItem = {
    id:string,
    name:string,
  }
  interface UpdateUserResponse {
    user: User
    success: boolean
  }

  

export const loginPwd = createAsyncThunk<
ResponseUserInfoDto,
Partial<LoginDto>,
// { id: string } & Partial<LoginDto>,
{
  rejectValue: ValidationErrors
}
>('userInfo/login', async (userData, { rejectWithValue }) => {
try {
  const { ...fields } = userData
  const response = await AuthService.login({ body:{password: fields!.password!, username: fields!.username! }})

  serviceOptions!.axios!.defaults!.headers!.common!.Authorization =
      "Bearer " + response.access_token;
  return response
} catch (err:any) {
  let error: AxiosError<ValidationErrors> = err // cast the error for access
  if (!error.response) {
    throw err
  }
  // We got validation errors, let's return those so we can reference in our component and set form errors
  return rejectWithValue(error.response.data)
}
})

// 创建一个实体适配器，可以对实体进行 CURD 操作
// https://redux-toolkit.js.org/api/createEntityAdapter
  const someEntityAdapter = createEntityAdapter<userInfoItem>({
    // Assume IDs are stored in a field other than `book.id`
    // 可以手动指定实体的 id 字段
    //   selectId: (book) => book.bookId,

    // Keep the "all IDs" array sorted based on book titles
    // 设置实体的排序依据（有 id 的数据）
    //   sortComparer: (a, b) => a.title.localeCompare(b.title),
  });

  export const userInfoSlice = createSlice({
    name: "userInfoSlice",
    initialState: someEntityAdapter.getInitialState({
      activeId: "",
      id:0,
      name:'',
      access_token:'',
      error:'',
      beforePath:'/'
    }),
    reducers: {
      loadUserInfo(state, action:PayloadAction<ResponseUserInfoDto>){
        let {access_token,user} = action.payload
        state.id = user.id
        serviceOptions!.axios!.defaults!.headers!.common!.Authorization =
        "Bearer " + access_token;
        localStorage.setItem('access_token',access_token)
      },
      // login(state, action:PayloadAction<LoginDto>){
      //   // let l = { password: password.value, username: username.value };
      //   let {password,username} = action.payload
      //   console.log(password,username)
      //   AuthService.login({ body:{password: password, username: username }})
      //     .then((res) => {
      //       serviceOptions!.axios!.defaults!.headers!.common!.Authorization =
      //         "Bearer " + res.access_token;
      //       localStorage.setItem("access_token", "Bearer " + res.access_token);

      //       // state.access_token = res.access_token

      //       state.id = res.access_token

      //     })
      //     .catch((res) => {});
      // },
      // setActiveId(state, action) {
      //   state.activeId = action.payload.id;
      // },
      // userInfoItemAdded(state, action:PayloadAction<userInfoItem>) {
      //   someEntityAdapter.addOne(state, action.payload);
      // },
      // userInfoItemRemoveOne:someEntityAdapter.removeOne,
      // userInfoItemUpdate: someEntityAdapter.updateOne,
    //   setState(state, action) {
    //     for (const key in action.payload) {
    //       if (Object.hasOwnProperty.call(action.payload, key)) {
    //         const element = action.payload[key];
    //         // @ts-ignore
    //         state[key] = JSON.parse(JSON.stringify(element));
    //       }
    //     }
    //   },
    },
    extraReducers: (builder) => {
      // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
      builder.addCase(loginPwd.fulfilled, (state, { payload }) => {
        // state.entities[payload.id] = payload
        // state.access_token = payload
        state.id = payload.user.id
        // state.name = payload.
      })
      builder.addCase(loginPwd.rejected, (state, action) => {
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.error = action.payload.errorMessage
        } else {
          state.error = action.error.message!
        }
      })
    },
  });
  
  export const {
    loadUserInfo
    // login
    // setState,
    // setActiveId,
    // userInfoItemAdded,
    // userInfoItemUpdate,
    // userInfoItemRemoveOne
  } = userInfoSlice.actions;

  // export const userInfoSlice.actions;
  
  export default userInfoSlice.reducer;
  
  export const userInfoItemSelectors = someEntityAdapter.getSelectors();
  