import { useSnackbar } from 'notistack';
import axios from 'axios'
import { serviceOptions } from './swaggerApi/index'
var apiAddress = require('../../package.json').apiAddress

export function SettingApiConfig(){
  console.log('SettingApiConfig');
  
  const { enqueueSnackbar } = useSnackbar();
  let address = ''
if(process.env.NODE_ENV === 'production'){
    address = apiAddress.production
    // address = '123'
}else{
    address = apiAddress.development
}
const instance = axios.create({
  baseURL: address,
  timeout: 100000,
  headers: {'X-Custom-Header': 'foobar'}
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  if(config.data === null){
    config.data = {}
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log('err',error)
  if(error.response.status == 401){
    localStorage.setItem('beforePath',window.location.href)
    // window.location.href='/sign-in'
    
  }
  enqueueSnackbar(error.response.statusText,{variant:'error',anchorOrigin:{vertical:'top',horizontal:'right'}});
  return Promise.reject(error);
});

instance.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');


serviceOptions.axios = instance
}

