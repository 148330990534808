import {  TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TodolistItemService, UpdateTodolistItemDto } from 'api/swaggerApi';
import { useParams } from 'react-router-dom';
// import { ModalProps } from 'pages/components/ModalProps';
import enumToArr from '../../../../utils/enumToArr';
import { ITEM_TYPE, MODAL_TYPE, PERIOD } from '../../../../utils/idToName';
import { Box, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { getData } from 'pages/shortcutStyle/redux/slice';
import { closeItemModal, resetForm } from './slice';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getItemDetail } from '../CollectionModal/slice';

export function TodolistItemModal() {



  const {
    modalShow,
    form,
    modalType
  } = useAppSelector((state) => {
    return state.subItemModalSlice;
  });
  const {
    form:parentForm
  } = useAppSelector((state) => {
    return state.itemModalSlice;
  });
  const {
    form:collectionForm,
    collectionItems
  } = useAppSelector((state) => {
    return state.collection;
  });

  const handleClose = () => {
    // onClose()
    // closeItemModal
    dispatch(closeItemModal())
  };
  const dispatch = useAppDispatch();
  let { id: collectionId } = useParams();


  React.useEffect(()=>{
    if(!modalShow){

      // dispatch(resetForm())
    }
      return () => {

      };
  },[modalShow])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // if (form) {

    let startAt = data.get('startAt')
    let desc = data.get('desc')
    let name = data.get('name')
    let period = data.get('period')
    let parent = data.get('parent')
    let id = undefined
    if (form) {
      id = form.id
    }

    let start = ''
    // if(isDate(startAt)){
    start = moment(startAt as string).format('YYYY-MM-DD')
    // }
    // if(isDate(endAt)){
    // end = moment(formHookInstance.endAt).format('YYYY-MM-DD')
    // }

    TodolistItemService.update({
      body: {
        id: id,
        name: name as string,
        desc: desc as string,
        startAt: start as string,
        period: period as unknown as number,
        // todolistId: form?.todolistId,
        parent:parent as unknown as number||parentForm.id,
        type:ITEM_TYPE.SUBITEM
      }
    }).then(r => {
      // onRefresh()
      // onClose()
      dispatch(getData({ id: collectionId as unknown as number }))
      dispatch(closeItemModal())
    })
    // }
  };
  const { menulist } = useAppSelector((state) => {
    return state.RouterMenuSlice;
  });
  function generateSubItems() {
    let arr:any =[]
    menulist.group.forEach(v=>{
      arr.push(<ListSubheader>{v.name}</ListSubheader>)
      menulist.collectionList
      .filter((item) => item.parent === v.id)
      .forEach((sub) => {
        arr.push(<MenuItem key={sub.id} value={sub.id}>
          {sub.name}
        </MenuItem>)
      });
    })
    
    return arr
  
}

useEffect(()=>{
  if(modalShow){

    dispatch(getItemDetail({id:collectionId as unknown as number}))
  }
},[modalShow])

  const [pickCollection, setPickCollection] = useState(-1);
  return <Dialog open={modalShow} onClose={()=>{}} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{MODAL_TYPE[modalType]}子项目</DialogTitle>
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <DialogContent>
        {/* <DialogContentText>
        更新 TODO
      </DialogContentText> */}

        {/* <FormContent formHookInstance={() => formHookInstance} /> */}
        <TextField

          margin="dense"
          id="name"
          name="name"
          label="名称"
          type="text"
          fullWidth
          defaultValue={form?.name}

        />
        <TextField

          margin="dense"
          id="desc"
          name="desc"
          label="描述"
          type="text"
          fullWidth
          defaultValue={form?.desc}

        />
          
          <FormControl variant="standard" fullWidth className="mt-6">
          <InputLabel id="select-label-period">
              周期
            </InputLabel>
          <Select
            fullWidth
            labelId="select-label-period"
            id="period"
            name="period"
            defaultValue={form.period || PERIOD.每天}
          >
            {enumToArr(PERIOD).map((v) => {
              return <MenuItem value={v}>{PERIOD[v]}</MenuItem>
            })}

          </Select>
          </FormControl>
          <FormControl variant="standard" fullWidth className="mt-6">
            <InputLabel id="demo-simple-select-helper-label2">
              选择集合
            </InputLabel>

            <Select
              label="选择集合"
              labelId="demo-simple-select-helper-label2"
              
              defaultValue={collectionForm.id}
              onChange={(e) => {
                // 需要调用 detail 方法获取指定集合中包含哪些
                let collectionId = e.target.value as number
                setPickCollection(collectionId);
                dispatch(getItemDetail({id:collectionId}))
              }}
            >
              {generateSubItems()}
            </Select>
            
          </FormControl>
          <FormControl variant="standard" fullWidth className="mt-6">
          <InputLabel id="demo-simple-select-helper-label3">
              所在项目
            </InputLabel>
            <Select
              label="选择项目"
              labelId="demo-simple-select-helper-label3"
              name="parent"
              defaultValue={parentForm.id}
            >
              {
                collectionItems.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              }

              {/* {generateSubItems()} */}
            </Select>
            
          </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          取消
      </Button>
        {/* <Button
        onClick={() => {
          // let start = ''
          // let end = ''
          // // if(isDate(startAt)){
          // start = moment(formHookInstance.startAt).format('YYYY-MM-DD')
          // // }
          // // if(isDate(endAt)){
          // // end = moment(formHookInstance.endAt).format('YYYY-MM-DD')
          // // }

          // TodolistItemService.update({
          //   body: {
          //     id: form.id,
          //     desc: formHookInstance.collectionDesc,
          //     startAt: start
          //     // endAt: end
          //   }
          // }).then(r => {
          //   onRefresh()
          //   onClose()
          // })

        }} color="primary">
        确定
      </Button> */}
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >确定</Button>
      </DialogActions>
    </Box>
  </Dialog>
}