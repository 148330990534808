import { Alert, Box, Button, Drawer } from "@mui/material";
import {
  getContentHistory,
  hideDrawer,
} from "pages/components/ContentHistoryDrawer/slice";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { ItemTemplate } from "pages/shortcutStyle/ItemTemplate";
import CollectionTemplate from "pages/shortcutStyle/CollectionTemplate";
import { ListTemplate } from "pages/shortcutStyle/ListTemplate";
import EditorJS from "@editorjs/editorjs";
// @ts-ignore
import AttachesTool from '@editorjs/attaches';
// @ts-ignore
import TextVariantTune from '@editorjs/text-variant-tune';
import ImageTool from "../../../../vendors/myImageTool/bundle";
import { useConfirm } from "material-ui-confirm";
import { TodolistItemCommentService } from "api/swaggerApi";
import { updateContent } from "../CommentDrawer/slice";
const Header = require("@editorjs/header");
const Marker = require("@editorjs/marker");
const RawTool = require("@editorjs/raw");
const List = require("@editorjs/list");
const Checklist = require("@editorjs/checklist");
const Quote = require("@editorjs/quote");
const Warning = require("@editorjs/warning");
const CodeTool = require("@editorjs/code");
const Delimiter = require("@editorjs/delimiter");
const InlineCode = require("@editorjs/inline-code");
const LinkTool = require("@editorjs/link");
const Embed = require("@editorjs/embed");
const Table = require("@editorjs/table");
function recover(arr: any) {
  // @ts-ignore
  let dmp = new window.GDMP.diff_match_patch()
  let his = arr;
  let v=""
  if(his[0].value==""){
    v = ""
  }else{

    v = JSON.stringify(his[0].value);
  }
  for (let index = 1; index < his.length; index++) {
    const element = his[index].value;
    v = dmp.patch_apply(element, v)[0];
  }
  console.log(v)
  return v;
}

export function ContentHistoryDrawer() {
  const { drawerShow, queryRes } = useAppSelector((state) => {
    return state.contentHistoryDrawerSlice;
  });
  let editorRef = React.useRef<EditorJS>();
  const confirm = useConfirm();
  const { id } = useAppSelector((state) => {
    return state.commentDrawerSlice;
  });
  const dispatch = useAppDispatch();

  // const refDebounceSave = React.useRef<Function>();
  // const refSave = React.useRef<any>();

  React.useEffect(() => {}, [drawerShow]);

  React.useEffect(() => {
    if (drawerShow) {
      dispatch(getContentHistory({ id }));
    }
    
    if (drawerShow && !editorRef.current) {
      let editor = new EditorJS({
        holderId: "editorjs_preview",
        readOnly: true,
        tunes: ['textVariant'],
        tools: {
          attaches: {
            class: AttachesTool,
            
          },
          textVariant: TextVariantTune,
          header: {
            class: Header,
            inlineToolbar: ["link"],
            config: {
              placeholder: "Header",
            },
            shortcut: "CMD+SHIFT+H",
          },
          image: {
            class: ImageTool,
            config: {
              types: "*/*",
              autoplay: 1,
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+L",
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            config: {
              quotePlaceholder: "Enter a quote",
              captionPlaceholder: "Quote's author",
            },
            shortcut: "CMD+SHIFT+O",
          },
          warning: Warning,
          marker: {
            class: Marker,
            shortcut: "CMD+SHIFT+M",
          },
          code: {
            class: CodeTool,
            shortcut: "CMD+SHIFT+C",
          },
          delimiter: { class: Delimiter, shortcut: "ALT+D" },
          inlineCode: {
            class: InlineCode,
            shortcut: "CMD+SHIFT+C",
          },
          linkTool: LinkTool,
          embed: Embed,
          table: {
            class: Table,
            inlineToolbar: true,
            shortcut: "CMD+ALT+T",
          },
          raw: RawTool,
        },
      });
      editorRef.current = editor;
    }
    
    if (!drawerShow) {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current?.destroy();
        editorRef.current = undefined;
      }
    }
  }, [drawerShow]);

  return (
    <Drawer
      anchor={"right"}
      open={drawerShow}
      onClose={() => {
        dispatch(hideDrawer());
      }}
      // className="CommentDrawer"
      //
    >
      <Box>
        <Button
          className="lg:hidden "
          color="warning"
          onClick={() => {
            // save()
            dispatch(hideDrawer());
          }}
        >
          关闭
        </Button>
      </Box>
      <Box
        sx={{
          background: "#f3f3f3",
          padding: "10px",
          height: "100%",
          overflow: "auto",
        }}
        className="drawer-width content-history-drawer"
      >
        <Box className="history-group ">
          {/* @ts-ignore */}
          {queryRes? queryRes.map((item, index) => {
                return (
                  <Box className="flex items-center">
                    <Box>{item.time}</Box>
                    <Button onClick={() => {
                      let v = ""
                      if(index==0){
                        // @ts-ignore
                        if(queryRes[0].value==""){
                          v = JSON.stringify([{ type: "paragraph", data: { text: "" } }])
                        }else{

                          // @ts-ignore
                          v = JSON.stringify(queryRes[0].value)
                        }
                      }else{

                        v = recover(queryRes.slice(0,index+1))
                      }
                      console.log('revocer',v)
                      if(v){

                        editorRef?.current?.render({ blocks: JSON.parse(v) });
                      }else{
                        
                        editorRef?.current?.render({ blocks: [{ type: "paragraph", data: { text: "" }}] });
                      }
                    }}>预览</Button>
                    <Button onClick={ ()=>{
                      // https://github.com/jonatanklosko/material-ui-confirm
                      confirm({ title:"温馨提示",description: "恢复后会重置修改记录",confirmationText:"确定恢复",cancellationText:"取消" }).then(async () => {
                        let v = ""
                      if(index==0){
                        // @ts-ignore
                        if(queryRes[0].value==""){
                          v = JSON.stringify([{ type: "paragraph", data: { text: "" } }])
                        }else{

                          // @ts-ignore
                          v = JSON.stringify(queryRes[0].value)
                        }
                      }else{

                        v = recover(queryRes.slice(0,index+1))
                      }
                      console.log('revocer',v)
                        // 在store提交修改
                        let res = await TodolistItemCommentService.recover({body:{id,content:v}})
                        if(res){
                          let contentRes = await TodolistItemCommentService.getContent({ body: { id: id } })
                          dispatch(hideDrawer())
                          // dispatch(updateContent(contentRes.content))
                          window.DiffObj.reset()
                          window.DiffObj.value = contentRes.content
                          // @ts-ignore
                          window.editor.render({ blocks: JSON.parse(contentRes.content) });
                        }
                      })
                      .catch(() => {
                        /* ... */
                      });
                    }}
                    >恢复</Button>
                  </Box>
                );
              })
            : null}
        </Box>
        <Box
            id="editorjs_preview"
            sx={{
              width: "100%",
              height: "calc(100% - 40px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          ></Box>
      </Box>
    </Drawer>
  );
}
