import { Alert, Box, Button, Drawer } from "@mui/material";
import { getLast, hideDrawer } from "pages/components/SearchHistoryDrawer/slice";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { ItemTemplate } from "pages/shortcutStyle/ItemTemplate";
import CollectionTemplate from "pages/shortcutStyle/CollectionTemplate";
import { ListTemplate } from "pages/shortcutStyle/ListTemplate";

export function SearchHistoryDrawer() {
  const { drawerShow, queryRes } = useAppSelector((state) => {
    return state.searchHistoryDrawerSlice;
  });
  const dispatch = useAppDispatch();

  

  React.useEffect(() => {
    if (drawerShow) {
      dispatch(getLast());
    }
  }, [drawerShow]);

  return (
    <Drawer
      anchor={"right"}
      open={drawerShow}
      onClose={() => {
        dispatch(hideDrawer());
      }}
      // className="CommentDrawer"
      //
    >
      <Box>
        <Button
          className="lg:hidden "
          color="warning"
          onClick={() => {
            // save()
            dispatch(hideDrawer());
          }}
        >
          关闭
        </Button>
      </Box>
      <Box
        sx={{
          background: "#f3f3f3",
          padding: "10px",
          height: "100%",
          overflow: "auto",
        }}
        className="drawer-width"
      >
        {/* <button onClick={(e) => {
          // set(e.target.value);
          dispatch(showDrawer({ id: 10 }));
        }}>cs</button> */}
        {/* v.type==1?<CollectionTemplate
          key={v.id}
          todolistId={v.id}
          index={v.id}
          item={v}
        />:v.type==2?<ListTemplate
        key={v.id}
        
        listData={v}
      />: */}
        {queryRes?.resArr.map((p) => {
          return (
            <>
              {/* @ts-ignore */}
              <Box  key={p.name}
                className=" text-gray-400 border-dashed pt-2 border-t-2 mt-2"
              >
                {/* @ts-ignore */}
                {p.name}
              </Box>

              {//@ts-ignore
              p.arr.map((v) => {
                return (
                  <ItemTemplate
                    disableBtn={true}
                    key={v.id}
                    todolistId={v.id}
                    index={v.id}
                    item={v}
                  />
                );
              })}
            </>
          );
        })}
        {/* {queryRes?.comment.map(v=>{
          return <ItemTemplate
          key={v.id}
          todolistId={v.id}
          index={v.id}
          item={v.}
        />
        })} */}
      </Box>
    </Drawer>
  );
}
