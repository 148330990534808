import {
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import {
  UpdateTodolistItemDto
} from "../../../../api/swaggerApi/index";
  
  import { MODAL_TYPE } from "utils/idToName";
 
  // export const addTodolistItem = createAsyncThunk<
  //   // 方法返回值
  //   // { content: string, responseId: number,id:number } | false,
  //   TodolistItem,
  //   // { res: TodolistItemComment, params: Partial<TodolistItem> } | null,
  //   // dispatch 参数
  //   { form:UpdateTodolistItemDto,collectionId:number },
  //   {
  //     rejectValue: ValidationErrors
  //   }
  // >('todolistItemModal/showDrawer', async (params, { rejectWithValue }) => {
  //   try {
      
          
  
  //   } catch (err: any) {
  //     let error: AxiosError<ValidationErrors> = err // cast the error for access
  //     if (!error.response) {
  //       throw err
  //     }
  //     // We got validation errors, let's return those so we can reference in our component and set form errors
  //     return rejectWithValue(error.response.data)
  //   }
  // })
  let initForm:UpdateTodolistItemDto = {}
  export const subItemModalSlice = createSlice({
    name: "subItemModalSlice",
    initialState: {
      modalShow:false,
      modalType:MODAL_TYPE.新增,
      form:initForm,
      
      // listId:0
  
  
      // item: <TodolistItem | null>null,
  
      // commentContent: <TodolistItemComment | null>null
    },
    reducers: {
      resetForm(state){
        state.form = initForm
      },
      // setTodolistItemModalStatus(state, action:PayloadAction<{status:boolean,listId:number}>){
      //   state.modalShow = action.payload.status
      //   state.listId = action.payload.listId
      // },
      closeItemModal(state){
        state.modalShow = false
        // state.listId = 0
      },
      addSubItemModal(state){
        state.modalShow = true
        state.modalType = MODAL_TYPE.新增
        // state.parentForm = action.payload
      },
      alterSubItemModal(state,action:PayloadAction<UpdateTodolistItemDto>){
        state.modalShow = true
        
        state.modalType = MODAL_TYPE.修改
        state.form = action.payload
      }
    //   hideDrawer(state) {
    //     state.drawerShow = false
    //     state.content = ''
    //     state.itemId = 0
    //     state.listId = 0
    //     state.collectionId = 0
    //     state.responseId = undefined
    //     state.isLoading = false
    //   },
    },
    extraReducers: (builder) => {
    //   builder.addCase(showDrawer.pending, (state, { payload }) => {
    //     state.drawerShow = true
    //     state.isLoading = true
    //   })
    //   builder.addCase(showDrawer.fulfilled, (state, { payload }) => {
    //     state.isLoading = false
    //     console.log('showDrawer',payload)
    //     if (payload!=false) {
    //       state.content = payload.content
    //       // window.editor.render()
    //       state.responseId = payload.responseId
    //       if(payload.source === 'item'){
    //         state.itemId = payload.id
    //         state.source = 'item'
    //       }
    //       if(payload.source === 'list'){
    //         state.listId = payload.id
    //         state.source = 'list'
    //       }
    //       if(payload.source === 'collection'){
    //         state.collectionId = payload.id
    //         state.source = 'collection'
    //       }
    //       // if(payload.userData){
  
    //       // state.item = payload.params as TodolistItem
    //       // }
    //     }
    //   })
    //   builder.addCase(showDrawer.rejected, (state, action) => {
    //   })
    //   builder.addCase(saveComment.pending, (state, { payload }) => {
    //     state.isSaving = false
    //   })
    //   builder.addCase(saveComment.fulfilled, (state, { payload }) => {
    //     state.isSaving = true
    //   })
    },
  });
  
  export const {
    // setTodolistItemModalStatus
    // showDrawer,
    // hideDrawer
    resetForm,
    alterSubItemModal,
    closeItemModal,
    addSubItemModal
  } = subItemModalSlice.actions;
  
  
  export default subItemModalSlice.reducer;
  